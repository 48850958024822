export const logActionsConstants = {
  'get_api_competencies-publish': 'Ассессмент Получить опубликованные компетенции',
  'get_api_delete-competency-questions': 'Ассессмент Удалить вопросы с компетенции',
  'get_api_v_course_assign-tester': 'Ассессмент Зарегистрироваться на курс',
  'get_api_v_get-user-results': 'Ассессмент Чтение результатов пользователя',
  'get_api_v_list-competencies': 'Ассессмент Чтение Списка компетенций',
  'get_api_v_oneid-users_search': 'Ассессмент Поиск пользователей',
  'get_api_v_proctoring-result': 'Ассессмент Получить результаты прокторинга',
  'get_api_v_users_procto-results': 'Ассессмент Чтение результатов прокторинга студента',
  'get_cert-templates': 'Ассессмент список шаблонов сертификатов',
  'get_course_assign-tester': 'Регистрация тестируемого на курс',
  'get_get-user-results': 'Получение результатов прокторинга пользователя',
  'get_list-competencies': 'Получение списка компетенций',
  'get_list-indicators': 'Список индикаторов',
  'get_models_get-by-id': 'Получение модели',
  'get_oneid-users_search': 'Поиск пользователей в oneid',
  'get_organizations_list-employee': 'Список пользователей',
  'get_proctoring-result': 'Получение результатов прокторинга',
  'get_testers_organization_testers-list': 'Ассессмент отчет о тестируемых организации',
  'get_user_find-by-id': 'Саппорт найти юзера по ID',
  'get_user_find-by-key-total': 'Саппорт поиск пользователей',
  'get_user_get-user-logs-list': 'Саппорт список логов пользователя',
  'get_user_get-user-orgs': 'Саппорт список организаций пользователя',
  'get_user_get-user-token': 'Саппорт получить токен пользователя',
  'get_user_send-user-email-code': 'Саппорт Переотправка смс код',
  'get_user_send-user-sms-code': 'Саппорт переотправка СМС код',
  'get_user_user-codes-info': '-',
  'get_users_procto-results': 'Получение результатов прокторинга',
  'patch_api_v_organizations_replace-users': '-',
  'patch_api_v_user_course-activity': 'Ассессмент Удалить студента с ОС',
  'patch_organization_update-organization': 'Ассессмент Обновить организацию',
  'patch_organizations_detach-employee': 'Удалить сотрудника',
  'patch_organizations_detach-hr': 'Открепить HR',
  'patch_organizations_replace-users': 'Обновление юзера организации',
  'patch_user_course-activity': 'Активация/деактивация курса тестируемого',
  'post_api_v_user_delete-course': 'Ассессмент Удалить курс',
  'post_cert-templates': 'Ассессмент Добавить шаблон сертификата',
  'post_fix-comp-questions': 'Ассессмент скрипт',
  'post_mails_notify-start-testing': '-',
  'post_mails_send-days': '-',
  'post_mails_send-inactive': '-',
  'post_organizations_upload-employee': 'Загрузить xlsx список сотрудников',
  'post_proctors_procto-external': 'Добавление результатов прокторинга',
  'post_send-account-manager': 'Отправка письма аккаунт менеджеру(курс на модерации)',
  'post_send-attempt': 'Отправка письма с напоминание о количестве попыток',
  'post_send-days': 'Отправка письма с напоминанием о количестве дней',
  'post_send-inactive': 'Отправка письма в случае если неактивен',
  'post_send-moderate': 'Отправка письма о том, что курс на модерации',
  'post_subordinate_assign-subordinate': 'Прикрепить подчиненного',
  'post_subordinate_detach-subordinate': 'Открепить подчиненного',
  'post_testers_organization_testers-registry': 'Ассессмент скачать реестр',
  'post_user_delete-course': 'Удаление курса тестера',
  'put_cert-templates': 'Ассессмент Обновить шаблон сертификата',
  'put_models_publish-by-id': 'Публикация модели',
  'put_models_update-by-id': 'Изменение модели',
  'put_proctoring-result': 'Ассессмент Обновить результаты прокторинга',
  activated_error_boundary: 'Фронт',
  assign_tester: 'Регистрация на курс',
  bind_callback_leader_id: 'Обратная отправка в leader id при привязке',
  bind_user_leader_id: 'Привязка лидер id',
  bind_user_telegram: 'Привязка telegram к профилю oneid',
  click_back_answer: 'Клик назад ответа',
  click_button_close_modal: 'Клик закрытия модального окна',
  click_button_modal: 'Клик кнопки модального окна',
  click_close_testing: 'Клик закрытия тестирования',
  click_continue_testing: 'Клик продолжения тестирования',
  click_create_course: 'Клик создания курса',
  click_create_registry: 'Клик на создание реестра',
  click_download_agreements: 'Фронт скачать соглашение',
  click_finish_testing: 'Клик окончания тестирования',
  click_next_answer: 'Клик следующего ответа',
  click_number_task: 'Клик номера задания',
  click_open_picture_answer: 'Клик открытия картинки ответа',
  click_open_picture_task: 'Клик открытия картинки задания',
  click_prev_answer: 'Клик предыдущего ответа',
  click_start_testing: 'Клик старта тестирования',
  click_variant_answer: 'Клик варианта ответа',
  click_watch_result: 'Клик просмотра результатов',
  confirm_by_code: 'Верификация по коду',
  confirm_email_profile: 'Подтверждение электронного ящика профиля',
  confirm_phone_profile: 'Подтверждение номера телефона профиля',
  confirm_telegram_profile: 'Проверка кода подтверждения для telegram',
  delete_api_v_block_delete: 'Ассессмент Удалить блок',
  delete_api_v_competency_delete: 'Ассессмент Удалить компетенцию',
  delete_api_v_course: 'Ассессмент Удалить ОС',
  delete_api_v_moderate_course: 'Ассессмент Удалить ОС на модерации',
  delete_api_v_organization: 'Удаление организации',
  delete_api_v_question: 'Ассессмент Удалить вопрос',
  delete_api_v_sphera_delete: 'Ассессмент Удалить сферу',
  delete_api_v_user_course: 'Ассессмент',
  delete_api_v_user_deleted_user_replaced: '-',
  delete_block_delete: 'Удаление блока компетенций',
  delete_block_published: 'Удаление блока',
  delete_competency_delete: 'Удалить компетенцию',
  delete_competency_published: 'Удаление компетенции',
  delete_course_user: '-',
  delete_course: 'Удаление курса',
  delete_doc: 'Удаление документов',
  delete_indicator: 'Удалить индикатор',
  delete_moderate_course: 'Удаление курса с модерации',
  delete_moderation_course: 'Удаление документа модерации',
  delete_organization: 'Ассессмент Удалить организацию',
  delete_organizations: 'Удаление организации',
  delete_positions: 'Удалить должность',
  delete_question: 'Удалить вопрос',
  delete_sphera_delete: 'Удаление сферы по ID',
  delete_sphere_delete: 'Удаление сферы',
  delete_user_id: 'Удаление юзера',
  delete_users: 'Изменения статуса пользователя на "deleted"',
  error_continue_testing: 'Энжин Ошибка продолжения тестирования',
  error_download_agreements: 'Ошибка скачивания соглашения',
  error_finish_testing: 'Энжин Ошибка завершения тестирования',
  error_start_testing: 'Фронт ошибка при старте тестирования',
  error_watch_result: 'Энжин Ошибка чтения результатов',
  esia_bind_user: 'Привязка учетной записи есиа к профилю oneid',
  esia_get_login_link: 'Получение ссылки для входа в есиа',
  esia_login_user: 'Вход пользователя через есиа',
  esia_unbind_user: 'Отвязка есиа от профиля oneid',
  external_procto_init: 'Ассессмент НАчать внешний прокторинг',
  finish_session: 'Окончание сессии',
  generate_session: 'Генерация сессии',
  get_anketas: 'Список анкет',
  get_api_v_category: 'Ассессмент Чтение справочника',
  get_api_v_competency_filter: 'Ассессмент Чтение компетенций по фильтрам',
  get_api_v_competency: 'Ассессмент Чтение компетенций',
  get_api_v_course_testers: 'Ассессмент Чтение студентов ОС',
  get_api_v_course: 'Ассессмент Чтение ОС',
  get_api_v_courses_tester: 'Ассессмент Чтение ОС студента',
  get_api_v_courses: 'Ассессмент Чтение курсов',
  get_api_v_dictionary_list: 'Ассессмент Список справочников',
  get_api_v_model: 'Ассессмент Чтение модели',
  get_api_v_models: 'Ассессмент Чтение моделей',
  get_api_v_moderate_course: 'Ассессмент Получить ОС на модерации',
  get_api_v_moderate_courses: 'Ассессмент Список курсов на модерации',
  get_api_v_moderate_organizations: 'Ассессмент Список организаций для модерации',
  get_api_v_organization_account_get: 'Ассессмент: Получить аккаунт менеджера',
  get_api_v_organization: 'Ассессмент Чтение организаций',
  get_api_v_organizations_testers: 'Ассессмент Чтение студентов организации',
  get_api_v_organizations_users: 'Ассессмент Чтение пользователей организации',
  get_api_v_questions_findby: 'Ассессмент Поиск вопросов',
  get_api_v_questions: 'Ассессмент Чтение заданий',
  get_api_v_reports: 'Ассессмент Чтение отчетов',
  get_api_v_user_course: 'Ассессмент Чтение ОС студента',
  get_api_v_user_courses: 'Ассессмент Чтение курсов студента',
  get_api_v_user: 'Ассессмент Чтение пользователя',
  get_api_v_users_dashboard: 'Ассессмент Получить дашбоард',
  get_api_v_users_search: 'Ассессмент Поиск пользователей',
  get_certificates: 'Получение списка сертификатов',
  get_competencies: 'Список компетенций',
  get_competency_filter: 'Поиск компетенций по фильтру',
  get_competency: 'Получение списка блока компетенций',
  get_course_testers: 'Получение тестеров курса',
  get_course: 'Получение курса по ID',
  get_courses_tester: 'Получение курсов тестируемого',
  get_courses: 'Поиск курсов с фильтрацией',
  get_dashboard_testers: 'Ассессмент получить дашбоард',
  get_dictionaries: 'Получение справочников',
  get_dictionary_list: 'Получение списка словарей',
  get_iprs: 'Список ИПР',
  get_login: 'Ассессмент запрос на авторизацию по токену',
  get_me: 'Получить данные аккаунта',
  get_model: 'Получение модели по ID',
  get_models: 'Получение списка моделей',
  get_moderate_course: 'Получение курса с модерации по ID',
  get_moderate_courses: 'Получение списка курсов на модерации с фильтрацией',
  get_moderate_organizations: 'Получить список организаций для аккаунт менеджера с количеством курсов',
  get_moderation_course: 'Данные модерации курса',
  get_organization_account_get: 'Получение аккаунт менеджера',
  get_organization_testers: 'Ассессмент Чтение студентов организации',
  get_organization_user_findbyemail: 'Ассессмент Поиск по почте',
  get_organization_users: 'Ассессмент Чтение пользователей организации',
  get_organization: 'Получение организации по ID',
  get_organizations_employee_info: 'Список сотрудников',
  get_organizations_positions: 'Должности организации',
  get_organizations_subordinates: 'Список подчиненных',
  get_organizations_testers: 'Получение тестеров организации',
  get_organizations_users: 'Получение юзеров организации',
  get_organizations: 'Ассессмент Чтение организаций',
  get_positions: 'Чтение должностей',
  get_procto_token: 'Ассессмент Сгенерировать токен для внешнего прокторинга',
  get_questions_findby: 'Найти список вопросов по фильтру',
  get_questions: 'Получить вопрос по ID',
  get_role_list: 'Получение всех пользователей ассессментa',
  get_search_assmnt: 'Ассессмент Поиск пользователей',
  get_search_oneid: 'Админка поиск пользователей',
  get_sphera_list: 'Получение списка сфер',
  get_support_organizations: 'Саппорт список организаций',
  get_tags: 'Привязка тега',
  get_testers_list: 'Ассессмент отчет о тестируемых',
  get_testers_organization: '-',
  get_testing_info: 'Энжин Получить информацию о тестировании',
  get_user_course: 'Получение курса пользователя',
  get_user_courses: 'Получение курсов пользователя',
  get_user_data: 'Получение базовых полей пользователя в профиле oneid',
  get_user_logs: '-',
  get_user: 'Получение пользователя по ID',
  get_users_all: 'Получение всех пользователей ассессментa',
  get_users_dashboard: 'Получение дашборда',
  get_users_get_all: 'Получение всех пользователей ассессментa',
  get_users_oneid: 'Ассессмент поиск пользователей в oneid',
  get_users_search: 'Поиск пользователей',
  get_users: 'Ассессмент поиск пользователей',
  head_courses_tester: '-',
  head_model: '-',
  head_user_course: '-',
  head_user: '-',
  login_callback_leader_id: 'Обратная отправка в leader id при авторизации',
  login_leader_id: 'Авторизация через leaderId',
  login_phone_email: 'Запрос кода для входа',
  login_psw: 'Авторизация пользователя',
  logout_leader_id: 'Выход из аккаунта leader id',
  logout: 'Выход из системы',
  patch_api_v_competency_archive: 'Ассессмент Архивация компетенции',
  patch_api_v_competency_edit: 'Ассессмент Обновить компетенцию',
  patch_api_v_competency_publish: 'Ассессмент Публикация компетенции',
  patch_api_v_course_moderate: 'Ассессмент Обновление ОС на модерации',
  patch_api_v_course_testers: 'Ассессмент',
  patch_api_v_course: 'Ассессмент Обновление ОС',
  patch_api_v_moderate_course_update: 'Ассессмент Обновление курса на модерации (оставить комменты)',
  patch_api_v_organization_account_create: 'Ассессмент Создать пользователя',
  patch_api_v_organization_account_delete: 'Ассессмент Открепить аккаунт менеджера',
  patch_api_v_organization: 'Ассессмент Обновление модели',
  patch_api_v_organizations: 'Ассессмент Обновить организацию',
  patch_api_v_question_archive: 'Ассессмент Архивация вопроса',
  patch_api_v_question: 'Ассессмент Обновление вопроса',
  patch_api_v_questions_published: 'Ассессмент Обновление опубликованного вопроса',
  patch_competency_archive: 'Архивирование блока',
  patch_competency_edit: 'Редактирование опубликованной компетенции',
  patch_competency_publish: 'Публикация блока',
  patch_course_files: 'Ассессмент обновить список студентов',
  patch_course_moderate: 'Отправка курса на модерацию',
  patch_course_testers: 'Обновление тестируемых курса',
  patch_course_title: 'Ассессмент обновить название опубликованной ОС',
  patch_course: 'Обновление курса',
  patch_moderate_course_update: 'Обновление курса на модерации',
  patch_organization_account_create: 'Добавление аккаунт менеджера',
  patch_organization_account_delete: 'Удаление аккаунт менеджера',
  patch_organization_activity: 'Удалить/Восстановить организацию',
  patch_organization: 'Ассессмент Обновить организацию',
  patch_organizations_employee: 'Обновить список сотрудников',
  patch_organizations_employers: 'Обновить список сотрудников',
  patch_organizations: 'Обновление организации',
  patch_positions: 'Обновить должность',
  patch_question_archive: 'Архивирование вопроса',
  patch_question_publish: 'Публикация вопроса',
  patch_question_restore: 'Восстановление вопроса',
  patch_question: 'Изменить вопрос',
  patch_questions_published: 'Обновление опубликованного вопроса',
  patch_testing_end: 'Энжин Завершить тестирование',
  patch_testing_start: 'Энжин Начать тестирование',
  patch_user_features: 'Ассессмент обновить согласия пользователя',
  patch_user_role: 'Ассессмент обновить роль пользователя',
  post_add_new_user: 'Добавление нового пользователя',
  post_anketas: 'Создать анкету',
  post_api_v_competency_new: 'Ассессмент Создать компетенцию',
  post_api_v_competency: 'Ассессмент Создание компетенции',
  post_api_v_course: 'Ассессмент Создать ОС',
  post_api_v_organization_account_feedback: 'Ассессмент Получить обратную связь',
  post_api_v_organization: 'Ассессмент Создание организации',
  post_api_v_organizations_user: 'Ассессмент Создать пользователя организации',
  post_api_v_question: 'Ассессмент Создание задания',
  post_api_v_questions_case: 'Ассессмент Создать вопрос с типом кейс',
  post_api_v_questions_duplicate: 'Ассессмент Дублировать вопрос',
  post_api_v_questions_matrix: 'Ассессмент Создание вопроса с типом матрица',
  post_api_v_sphera_duplicate: 'Ассессмент Дублировать сферу',
  post_api_v_sphera_new: 'Ассессмент Создать сферу',
  post_api_v_upload_files: 'Ассессмент Загрузка файлов',
  post_api_v_user_login: 'Ассессмент логин',
  post_competency_add: 'Ассессмент добавить компетенцию',
  post_competency_duplicate: 'Создание дубликата компетенции',
  post_competency_new: 'Создание компетенции',
  post_competency: 'Создание компетенций',
  post_course_moderate: 'Создание курса в статусе "Модерация"',
  post_course: 'Создание курса в статусе "Черновик"',
  post_dashboard_testers_init: 'Ассессмент Инициализация дашборда',
  post_enroll_rerun: 'Ассессмент перезапуск регистрации по списку',
  post_feedbacks_send: 'добавить отзыв на вопрос',
  post_indicator: 'создать индикатор',
  post_iprs_approval: 'Согласование ИПР',
  post_iprs: 'Создать ИПР',
  post_mod_assign: '-',
  post_mod_detach: 'Прикрепить модератора',
  post_mod_feedback: 'Отправить обратную связь',
  post_models: 'Создание модели',
  post_moderation_course: 'Добавление документа модерации',
  post_notifications_init: 'Начать рассылку',
  post_organization_account_feedback: 'Отзыв на аккаунт менеджера',
  post_organization_add_user: 'Ассессмент Добавить пользователя',
  post_organization: 'Создание организации',
  post_organizations_hr: 'Добавить HR',
  post_organizations_position: 'Создать должность',
  post_organizations_user: 'Добавление юзера в организацию',
  post_proctors_token: 'Генерация внешнего токена(прокторинг)',
  post_question_feedback: 'Отправка фидбек на вопрос',
  post_question: 'Создание вопроса',
  post_questions_case: 'Создание вопроса типа case',
  post_questions_duplicate: 'Создать дубликат вопроса',
  post_questions_matrix: 'Создание вопроса типа matrix',
  post_questions_open: 'Ассессмент добавить открытый вопрос',
  post_questions_tree: 'Ассессмент добавить задание с типом древо',
  post_rolen_new: 'Добавление новой роли пользователю',
  post_sphera_add: 'Ассессмент добавить сферу',
  post_sphera_duplicate: 'Дублирование сферы по ID',
  post_sphera_new: 'Создание сферы',
  post_tag: 'Ассессмент Создать тег',
  post_upload_file_pdf: 'Ассессмент Загрузить ПДФ',
  post_upload_file_spreadsheet: 'Ассессмент Загрузить xlsx',
  post_upload_files: 'Загрузка файлов',
  post_upload_image: 'Oneid Загрузка изображения',
  post_user_auth: 'Саппорт авторизация',
  post_user_login: 'Авторизация',
  post_user: 'Ассессмент админка добавить пользователя',
  post_users: 'Создать пользователя',
  put_api_v_block_update: 'Ассессмент Обновление блока',
  put_api_v_competency_update: 'Ассессмент Обновление компетенции',
  put_api_v_sphera_update: 'Ассессмент Обновление сферы',
  put_block_update: 'Обновление блока компетенций',
  put_builder_updates: 'Уведомление от билдера',
  put_competency_update: 'Обновить компетенцию',
  put_iprs: 'Обновить список ИПР',
  put_organization_info: 'Обновить организацию',
  put_organizations_employee_skills: 'Обновить навыки сотрудника',
  put_sphera_update: 'Обновление сферы по ID',
  read_course_results: 'Чтение результатов курса',
  read_passed_test: 'Энжин Чтение результатов',
  read_test_info: 'Ассессмент получить результаты тестирования',
  read_user_results: 'Чтение результатов юзера',
  recovery_psw_check: 'Проверка токена восстановления пароля',
  recovery_psw_req: 'Запрос на восстановление пароля по номеру телефона',
  recovery_psw_save: 'Сохранение нового пароля при восстановлении',
  send_email_code: 'Отправка письма с кодом на email',
  send_sms_code: 'Отправка смс с кодом на номера телефона',
  set_personal_agreement: 'Установка полей по персональному соглашению в профиле oneid',
  set_phone_email: 'Сохранение публичной информации о пользователе',
  test_actions: 'Проверка действий',
  test_front_actions: 'Проверка действий фронтенда',
  upd_doc: 'Обновление документов',
  upd_email: 'Изменение электронного ящика профиля',
  upd_phone: 'Обновление номера телефона',
  upd_psw: 'Обновление пароля',
  update_session: 'Открытие вопроса или ответ на вопрос',
  upload_doc: 'Загрузка документов',
  upload_file: 'Загрузка изображения',
};

export const logActionsOneIdConstants = {
  add_user: 'Регистрация пользователя',
  login_psw: 'Авторизация пользователя',
  logout: 'Выход из системы',
  login_phone_email: 'Запрос кода для входа',
  confirm_by_code: 'Верификация по коду',
  set_phone_email: 'Сохранение публичной информации о пользователе',
  upd_psw: 'Обновление пароля',
  upload_file: 'Загрузка изображения',
  upd_phone: 'Обновление номера телефона',
  confirm_phone_profile: 'Подтверждение номера телефона профиля',
  upd_email: 'Изменение электронного ящика профиля',
  confirm_email_profile: 'Подтверждение электронного ящика профиля',
  send_sms_code: 'Отправка смс с кодом на номера телефона',
  send_email_code: 'Отправка письма с кодом на email',
  recovery_psw_req: 'Запрос на восстановление пароля  по номеру телефона',
  recovery_psw_check: 'Проверка токена восстановления пароля',
  recovery_psw_save: 'Сохранение нового пароля при восстановлении',
  upload_doc: 'Загрузка документов',
  delete_doc: 'Удаление документов',
  upd_doc: 'Обновление документов',
  bind_user_leader_id: 'Привязка лидер id',
  bind_callback_leader_id: 'Обратная отправка в leader id при привязке',
  login_leader_id: 'Авторизация через leaderid',
  login_callback_leader_id: 'Обратная отправка в leader id при авторизации',
  logout_leader_id: 'Выход из аккаунта leader id',
};
